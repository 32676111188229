import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IDialogCancellableEventArgs, IDialogEventArgs, OverlaySettings } from '@infragistics/igniteui-angular';

import { Destroyable, InputValidators } from '@supy/common';
import { DialogComponent, IDialogComponent } from '@supy/components';

import { EditTenantRequest, ProviderEnum } from '../../models';

@Component({
  selector: 'supy-edit-tenant-dialog',
  templateUrl: './edit-tenant-dialog.component.html',
  styleUrls: ['./edit-tenant-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditTenantDialogComponent extends Destroyable implements IDialogComponent {
  @ViewChild(DialogComponent, { static: true }) readonly dialog: DialogComponent;

  @Input() readonly title: string;
  @Input() readonly tenantId: string;
  @Input() readonly providerBrand: ProviderEnum;
  @Input() readonly cancelText?: string = $localize`:@@cancel:Cancel`;
  @Input() readonly confirmText?: string = $localize`:@@common.actions.submit:Submit`;
  @Input() readonly navigateOnConfirm?: boolean;
  @Input() readonly redirectUrl?: string[];
  @Input() readonly overlaySettings?: OverlaySettings;

  @Output() readonly dialogClosing = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogClosed = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogOpening = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogOpened = new EventEmitter<IDialogEventArgs>();
  @Output() readonly confirm = new EventEmitter<EditTenantRequest>();
  @Output() readonly cancel = new EventEmitter<void>();

  protected readonly searchValue = signal('');

  protected providers = ProviderEnum;

  protected readonly form = new FormGroup({
    name: new FormControl<string>('', [Validators.required, InputValidators.names]),
  });

  onDialogClosing(event: IDialogCancellableEventArgs): void {
    this.dialogClosing.emit(event);
  }

  onDialogClosed(event: IDialogEventArgs): void {
    this.dialogClosed.emit(event);
  }

  onDialogOpening(event: IDialogCancellableEventArgs): void {
    this.dialogOpening.emit(event);
  }

  onDialogOpened(event: IDialogEventArgs): void {
    this.dialogOpened.emit(event);
  }

  onCancelClick(): void {
    this.closeDialog();
    this.cancel.emit();
  }

  onConfirmClick(): void {
    this.closeDialog();

    if (this.form.value.name) {
      this.confirm.emit({ id: this.tenantId, name: this.form.value.name });
    }
  }

  openDialog(): void {
    this.dialog.openDialog();
  }

  closeDialog(): void {
    this.dialog.closeDialog();
  }
}
