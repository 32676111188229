import {
  ProviderEnum,
  ProviderItemCategory,
  ProviderLocation,
  ProviderResource,
  ProviderSalesType,
  ProviderSupplier,
  ProviderTaxRate,
  ResourceTypeEnum,
  TenantHealthEnum,
} from '../../tenant';
import {
  CreateMappingsRequest,
  CreateOneMappingRequest,
  Mapping,
  MappingCategoryEnum,
  MappingMetadata,
  MappingRequests,
  MappingResource,
  UpdateMappingRequest,
  UpdateMappingsRequest,
} from '../models';

export type MappingAction = 'create' | 'update' | 'delete';

export interface MappingGridEntry {
  readonly id: string;
  readonly mappingId?: string;
  readonly mappingMetadata?: MappingMetadata;
  readonly status: TenantHealthEnum;
  readonly primaryResource: MappingResource;
  readonly secondaryResource?: MappingResource | null;
}

export function getMappingAction(entry: MappingGridEntry): MappingAction {
  const actions = {
    create: entry.secondaryResource && entry.status === TenantHealthEnum.Incomplete,
    update: entry.secondaryResource && entry.status !== TenantHealthEnum.Incomplete,
    delete: !entry.secondaryResource && entry.status !== TenantHealthEnum.Incomplete,
  };

  return Object.entries(actions)
    .find(([action, trueness]) => trueness)
    ?.at(0) as MappingAction;
}

export function getMappingRequestPayloads(
  tenantId: string,
  resourceType: ResourceTypeEnum,
  category: MappingCategoryEnum,
  data: { action: MappingAction; entry: MappingGridEntry }[],
  reversed = false,
): MappingRequests {
  return Array.from(data).reduce(
    (requests: MappingRequests, { action, entry }) => {
      if (action === 'create') {
        requests[action] = getCreateMappingPayload(
          tenantId,
          resourceType,
          entry,
          requests[action].mappings,
          category,
          reversed,
        );
      }

      if (action === 'update') {
        requests[action] = getUpdateMappingPayload(resourceType, entry, requests[action].mappings, reversed);
      }

      if (action === 'delete') {
        requests[action].mappingIds.push(entry.mappingId as string);
      }

      return requests;
    },
    { create: { tenantId: '', mappings: [] }, update: { mappings: [] }, delete: { mappingIds: [] } } as MappingRequests,
  );
}

function getProviderResourceName(resourceType: ResourceTypeEnum, entry: MappingResource | null | undefined) {
  if (!entry) {
    return null;
  }

  return entry.name;
}

function getCreateMappingPayload(
  tenantId: string,
  resourceType: ResourceTypeEnum,
  entry: MappingGridEntry,
  mappings: CreateOneMappingRequest[],
  category: MappingCategoryEnum,
  reversed: boolean,
): CreateMappingsRequest {
  const providerResourceName = reversed
    ? getProviderResourceName(resourceType, entry.primaryResource)
    : getProviderResourceName(resourceType, entry.secondaryResource);

  const providerResourceId = reversed ? entry.primaryResource.id : (entry.secondaryResource?.id as string);

  return {
    tenantId,
    category,
    mappings: [
      ...mappings,
      {
        resourceType: resourceType,
        providerResource: { id: providerResourceId, ...(providerResourceName ? { name: providerResourceName } : {}) },
        supyResource: reversed ? { id: entry.secondaryResource?.id } : { id: entry.primaryResource.id },
      },
    ],
  };
}

function getUpdateMappingPayload(
  resourceType: ResourceTypeEnum,
  entry: MappingGridEntry,
  mappings: UpdateMappingRequest[],
  reversed: boolean,
): UpdateMappingsRequest {
  const providerResourceName = reversed
    ? getProviderResourceName(resourceType, entry.primaryResource)
    : getProviderResourceName(resourceType, entry.secondaryResource);

  const primaryResource = {
    id: entry.primaryResource.id,
    ...(providerResourceName ? { name: providerResourceName } : {}),
  };
  const secondaryResource = {
    id: entry.secondaryResource?.id as string,
    ...(providerResourceName ? { name: providerResourceName } : {}),
  };

  return {
    mappings: [
      ...mappings,
      {
        id: entry.mappingId as string,
        providerResource: reversed ? primaryResource : secondaryResource,
        supyResource: reversed ? { id: secondaryResource.id } : { id: primaryResource.id },
      },
    ],
  };
}

export function mapProviderResources(
  resourceType: ResourceTypeEnum,
  resources: ProviderResource[],
  provider: ProviderEnum,
): MappingResource[] {
  switch (resourceType) {
    case ResourceTypeEnum.Location:
      return mapLocationResources(resources);
    case ResourceTypeEnum.Branch:
      return mapBranchResources(resources);
    case ResourceTypeEnum.Supplier:
      return mapSuppplierResources(resources);
    case ResourceTypeEnum.TaxRate:
      return mapTaxRateResources(resources, provider);
    case ResourceTypeEnum.AccountingCategory:
      return mapAccountingCategoryResources(resources);
    case ResourceTypeEnum.SalesType:
      return mapSalesTypesResources(resources);
    default:
      return mapLocationResources(resources);
  }
}

function mapLocationResources(resources: ProviderResource[]): MappingResource[] {
  const providerLocations = resources as ProviderLocation[];

  return providerLocations.map(providerLocation => ({
    ...providerLocation,
    id: providerLocation.id,
    name: providerLocation.displayName,
  }));
}

function mapBranchResources(resources: ProviderResource[]): MappingResource[] {
  const providerBranches = resources as ProviderLocation[];

  return providerBranches.map(providerBranch => ({
    ...providerBranch,
    id: providerBranch.id,
    name: providerBranch.displayName,
  }));
}

function mapSuppplierResources(resources: ProviderResource[]): MappingResource[] {
  const providerSuppliers = resources as ProviderSupplier[];

  return providerSuppliers.map(providerSupplier => ({
    ...providerSupplier,
    id: providerSupplier.id,
    name: providerSupplier.displayName ?? 'NA',
  }));
}

function mapTaxRateResources(resources: ProviderResource[], provider: ProviderEnum): MappingResource[] {
  const taxIdProviders = [ProviderEnum.Myob, ProviderEnum.OracleNetsuite, ProviderEnum.Sftp];
  const providerTaxRates = resources as ProviderTaxRate[];

  return providerTaxRates.map(providerTaxRate => ({
    ...providerTaxRate,
    id: (taxIdProviders.includes(provider) ? providerTaxRate.id : providerTaxRate.type) as string,
    name: `${providerTaxRate.displayName}`,
  }));
}

function mapAccountingCategoryResources(resources: ProviderResource[]): MappingResource[] {
  const providerCategories = resources as ProviderItemCategory[];

  return providerCategories.map(providerCategory => ({
    ...providerCategory,
    id: providerCategory.id,
    name: `${providerCategory.code ? providerCategory.code + ' -' : ''} ${providerCategory.displayName}`,
  }));
}

function mapSalesTypesResources(resources: ProviderResource[]): MappingResource[] {
  const providerSalesTypes = resources as ProviderSalesType[];

  return providerSalesTypes.map(providerSalesType => ({
    id: providerSalesType.code,
    name: providerSalesType.name,
  }));
}

export function mapGridEntry(
  primaryResource: MappingResource,
  secondaryResource: MappingResource,
  mapping: Mapping,
): MappingGridEntry {
  return {
    id: primaryResource.id,
    mappingId: mapping?.id,
    mappingMetadata: mapping?.metadata,
    primaryResource,
    secondaryResource,
    status: mapping
      ? secondaryResource
        ? TenantHealthEnum.Good
        : TenantHealthEnum.Error
      : TenantHealthEnum.Incomplete,
  };
}
