<div class="supy-edit-tenant-dialog">
  <supy-dialog
    position="center"
    initialState="open"
    width="40vw"
    (dialogClosed)="onDialogClosed($event)"
    (dialogClosing)="onDialogClosing($event)"
    (dialogOpened)="onDialogOpened($event)"
    (dialogOpening)="onDialogOpening($event)"
  >
    <supy-dialog-header>
      <h3 class="supy-edit-tenant-dialog__header-title">{{ title }}</h3>
    </supy-dialog-header>
    <supy-dialog-content>
      <div [formGroup]="form" class="supy-edit-tenant-dialog__form">
        <label class="supy-branch-form__label">Tenant name</label>
        <supy-input placeholder="Tenant Name" type="text" formControlName="name" name="name">
          <supy-input-error *supyErrorIf="'required'">Tenant name is required</supy-input-error>
          <supy-input-error *supyErrorIf="'name'">Invalid name</supy-input-error>
        </supy-input>
      </div>
    </supy-dialog-content>
    <supy-dialog-footer>
      <div class="supy-edit-tenant-dialog__buttons">
        <supy-button (buttonClick)="onCancelClick()" color="default" name="cancel">{{ cancelText }}</supy-button>
        <supy-button (buttonClick)="onConfirmClick()" [disabled]="form.invalid" color="secondary" name="confirm">{{
          confirmText
        }}</supy-button>
      </div>
    </supy-dialog-footer>
  </supy-dialog>
</div>
