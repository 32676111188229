import { inject, InjectionToken } from '@angular/core';

import { EndpointConfig } from '@supy/common';
import { APP_CONFIG } from '@supy/core';

export const TENANT_BFF_URI = new InjectionToken<string>('Tenant URI', {
  factory() {
    const config = inject<EndpointConfig>(APP_CONFIG);

    return `${config.apiUrlBff}${config.apiUrlPrefix}/tenants`;
  },
});
