import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { SupyCommonModule } from '@supy/common';
import {
  ButtonModule,
  CardModule,
  ComboboxModule,
  DateInputModule,
  DateTimeEditorModule,
  DialogModule,
  GridModule,
  IconModule,
  ImageModule,
  InputModule,
  LoadingOverlayModule,
  SelectModule,
  SeparatorModule,
  StatusBadgeModule,
  SwitchModule,
  TooltipModule,
} from '@supy/components';

import {
  ActivateTenantDialogComponent,
  EditTenantDialogComponent,
  IntegrationConnectDialogComponent,
  IntegrationsGridComponent,
  IntegrationTileComponent,
  SelectTenantSyncMethodDialogComponent,
  TenantStatusBadgeComponent,
} from './components';

@NgModule({
  declarations: [
    IntegrationsGridComponent,
    IntegrationTileComponent,
    TenantStatusBadgeComponent,
    IntegrationConnectDialogComponent,
    ActivateTenantDialogComponent,
    EditTenantDialogComponent,
    SelectTenantSyncMethodDialogComponent,
  ],
  exports: [
    IntegrationsGridComponent,
    IntegrationTileComponent,
    TenantStatusBadgeComponent,
    IntegrationConnectDialogComponent,
    ActivateTenantDialogComponent,
    EditTenantDialogComponent,
    SelectTenantSyncMethodDialogComponent,
  ],
  imports: [
    CommonModule,
    CommonModule,
    SupyCommonModule,
    ReactiveFormsModule,
    GridModule,
    IconModule,
    CardModule,
    ButtonModule,
    DialogModule,
    SelectModule,
    DateInputModule,
    SeparatorModule,
    SwitchModule,
    InputModule,
    LoadingOverlayModule,
    DateTimeEditorModule,
    TooltipModule,
    StatusBadgeModule,
    ComboboxModule,
    ImageModule,
  ],
})
export class TenantModule {}
