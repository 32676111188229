import { IdType, LocalizedData } from '@supy/common';

import { ResourceTypeEnum } from '../../tenant';
import { Mapping, MappingMetadata } from './mapping.entity';

export interface GetMappingsRequest extends Mapping {
  readonly 'providerResource.id'?: string;
  readonly 'supyResource.id'?: string;
}

export enum MappingCategoryEnum {
  Pos = 'pos',
  Accounting = 'accounting',
}

export interface ProviderResourceRequest extends IdType {
  readonly name?: unknown;
  readonly code?: unknown;
}

export interface CreateMappingRequest {
  readonly tenantId: string;
  readonly resourceType: ResourceTypeEnum;
  readonly supyResource: IdType;
  readonly providerResource: ProviderResourceRequest;
  readonly metadata: MappingMetadata;
}

export class CreateOneMappingRequest {
  readonly resourceType: ResourceTypeEnum;
  readonly supyResource: IdType;
  readonly providerResource: ProviderResourceRequest;
  readonly metadata?: MappingMetadata;
}

export interface CreateMappingsRequest {
  readonly tenantId: string;
  readonly category?: MappingCategoryEnum;
  readonly mappings: CreateOneMappingRequest[];
}

export interface UpdateMappingRequest {
  readonly id?: string;
  readonly supyResource?: IdType;
  readonly providerResource?: ProviderResourceRequest;
  readonly metadata?: MappingMetadata;
}

export interface UpdateMappingsRequest {
  readonly mappings: UpdateMappingRequest[];
}

export interface DeleteMappingsRequest {
  readonly mappingIds: string[];
}

export interface MappingRequests {
  create: CreateMappingsRequest;
  update: UpdateMappingsRequest;
  delete: DeleteMappingsRequest;
}

export type BranchWithMapping = {
  readonly branchId: string;
  readonly branchName: LocalizedData;
  readonly branchCutoffTime: number;
} & Partial<Mapping>;
