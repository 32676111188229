import { inject, InjectionToken } from '@angular/core';

import { EndpointConfig } from '@supy/common';
import { APP_CONFIG } from '@supy/core';

export interface SalesEventsConfig extends EndpointConfig {}

export const SALES_IMPORTS_URI = new InjectionToken<string>('POS Sales Imports URI', {
  factory: () => {
    const config = inject<EndpointConfig>(APP_CONFIG);

    return `${config.apiUrlBff}${config.apiUrlPrefix}/sales-imports`;
  },
});

export const SALES_TRANSACTIONS_URI = new InjectionToken<string>('POS Sales Transactions URI', {
  factory: () => {
    const config = inject<EndpointConfig>(APP_CONFIG);

    return `${config.apiUrlBff}${config.apiUrlPrefix}/sales-transactions`;
  },
});
